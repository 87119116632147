import React, { useRef } from "react"
import Footer from "../components/Footer"
import Cursor from "../components/Cursor"
import styles from "./index.module.scss"

const Home = () => {
  return (
    <>
      <section className={styles.underConstructionBg}>
        <div className={styles.underConstruction}>
          <div>coming soon!</div>
          <a href="mailto:nat@uduwela.com">nat@uduwela.com</a>
        </div>
      </section>
    </>
  )
}

export default Home
